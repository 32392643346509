import { toTitleCase } from './to-title-case.helper';

export const getComparison = (personMatch, stacktrace) => {
  const getIsMatching = (key, name, stack) =>
    stack.find((element) => element.name === name && element.key === key)
      ?.isMatching;

  const getNameAtIndex = (name, index = 0) => name?.split(' ')[index];

  const hasSecondName = (name) => !!getNameAtIndex(name, 1);

  const getName = (key) => {
    switch (key) {
      case 'givenName1':
        return 'Vorname';
      case 'givenName':
        return '1. Vorname';
      case 'secondName':
        return '2. Vorname';
      case 'familyName':
        return 'Familienname';
      case 'birthDate':
        return 'Geburtsdatum';
      case 'postalCode':
        return 'PLZ';
      default:
        return '';
    }
  };

  const oneOfTheSourcesHasSecondName = (personMatch2) => {
    const ssu = personMatch2.ssu.givenName.includes(' ');
    const ident = personMatch2.ident.givenName.includes(' ');

    return ssu || ident;
  };

  const getComparisonResult = (key) => [
    {
      key: oneOfTheSourcesHasSecondName(personMatch)
        ? getName('givenName')
        : getName('givenName1'),
      value: toTitleCase(getNameAtIndex(personMatch?.[key]?.givenName)),
      isMatching:
        getIsMatching('givenName', 'GIVEN_NAME_MATCHES', stacktrace) ||
        getIsMatching('givenName', 'FIRST_GIVEN_NAME_MATCHES', stacktrace) ||
        getIsMatching('givenName', 'ANY_GIVEN_NAME_MATCHES', stacktrace),
    },
    // eslint-disable-next-line no-nested-ternary
    ...(hasSecondName(personMatch?.[key]?.givenName)
      ? [
          {
            key: getName('secondName'),
            value: toTitleCase(
              getNameAtIndex(personMatch?.[key]?.givenName, 1)
            ),
            isMatching:
              getIsMatching('givenName', 'GIVEN_NAME_MATCHES', stacktrace) ||
              getIsMatching(
                'givenName',
                'FIRST_GIVEN_NAME_MATCHES',
                stacktrace
              ) ||
              getIsMatching('givenName', 'ANY_GIVEN_NAME_MATCHES', stacktrace),
          },
        ]
      : oneOfTheSourcesHasSecondName(personMatch)
      ? [
          {
            key: getName('secondName'),
            isMatching:
              getIsMatching('givenName', 'GIVEN_NAME_MATCHES', stacktrace) ||
              getIsMatching(
                'givenName',
                'FIRST_GIVEN_NAME_MATCHES',
                stacktrace
              ) ||
              getIsMatching('givenName', 'ANY_GIVEN_NAME_MATCHES', stacktrace),
          },
        ]
      : []),
    {
      key: getName('familyName'),
      value: toTitleCase(personMatch?.[key]?.familyName) || 'nicht vorliegen',
      isMatching: getIsMatching(
        'familyName',
        'FAMILY_NAME_MATCHES',
        stacktrace
      ),
    },
    {
      key: getName('birthDate'),
      value: personMatch?.[key]?.birthDate || 'nicht vorliegen',
      isMatching: getIsMatching('birthDate', 'BIRTHDAY_MATCHES', stacktrace),
    },
    {
      key: getName('postalCode'),
      value: personMatch?.[key]?.address?.postalCode || 'nicht vorliegen',
      isMatching: getIsMatching(
        'postalCode',
        'POSTAL_CODE_MATCHES',
        stacktrace
      ),
    },
  ];

  return {
    ident: getComparisonResult('ident'),
    ssu: getComparisonResult('ssu'),
  };
};

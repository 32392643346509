/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { Show, SimpleShowLayout, TextField, Button } from 'react-admin';

import { OrderDetailStyle } from './OrderDetail.style';
import { ShowOrderRequests } from './ShowOrderRequests';
import { OrderStepTable } from '../order-steps/OrderStepTable';

export const OrderDetail = (props) => {
  /* eslint-disable-next-line react/prop-types */
  const { record } = props;
  const classes = OrderDetailStyle();

  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  const label = show === false ? 'Anzeigen' : 'Verstecken';

  return (
    <>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField label='Statusverlauf' textAlign='left' />
          <OrderStepTable
            width='1200px'
            source='states'
            label='Status'
            textAlign='left'
          />
          <TextField
            className={classes.textField}
            source='processDuration'
            label='Dauer des Identifikationsverfahrens'
            textAlign='left'
          />
          {/* eslint-disable-next-line react/prop-types */}
          {record.personRequests?.length ? (
                  <>
                    <TextField label='Order Requests' />
                    <Button
                        className={classes.button}
                        label={label}
                        onClick={changeShow}
                    />
                    {show ? <ShowOrderRequests /> : ''}
                  </>
          ): ''}

        </SimpleShowLayout>
      </Show>
    </>
  );
};
